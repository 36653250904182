import docReady from '../../../assets/js/docReady';

function initHowWeHelp() {
    const desktopLayout = document.querySelector('.how-we-help .desktop-layout');
    const mobileCarousel = document.querySelector('.how-we-help .mobile-carousel');
    const stepLabels = desktopLayout.querySelectorAll('.step-label');
    const desktopImages = desktopLayout.querySelectorAll('.how-image img');
    const carousel = mobileCarousel.querySelector('.carousel-container');
    const cards = carousel.querySelectorAll('.carousel-card');
    const prevBtn = mobileCarousel.querySelector('.carousel-control.prev');
    const nextBtn = mobileCarousel.querySelector('.carousel-control.next');

    let currentStep = 0;
    let intervalId;
    let isHovering = false;
    let isInteracting = false;

    let cardWidth;
    let cardMargin;
    let totalCardWidth;

    function updateDimensions() {
        cardWidth = cards[0].offsetWidth;
        cardMargin = parseInt(getComputedStyle(cards[0]).marginLeft) * 2;
        totalCardWidth = cardWidth + cardMargin;
    }

    function updateButtonVisibility() {
        prevBtn.style.display = currentStep === 0 ? 'none' : 'flex';
        nextBtn.style.display = currentStep === cards.length - 1 ? 'none' : 'flex';
    }

    function updateDesktop(index) {
        stepLabels.forEach((label, i) => {
            label.classList.toggle('active', i === index);
        });

        desktopImages.forEach((img, i) => {
            if (i === index) {
                img.style.display = 'block';
                img.style.opacity = '0';
                // img.style.transform = 'translateX(-20px) scale(1.05)';

                requestAnimationFrame(() => {
                    img.style.transition = 'none';
                    // img.offsetHeight; // Trigger reflow
                    img.style.transition = 'opacity 0.5s ease, transform 0.5s ease';
                    img.style.opacity = '1';
                    // img.style.transform = 'translateX(0) scale(1)';
                });
            } else {
                img.style.display = 'none';
            }
        });
    }

    function updateMobile(animate = true) {
        updateDimensions();
        const offset = getOffset();

        requestAnimationFrame(() => {
            carousel.style.transition = animate ? 'transform 0.3s ease-out' : 'none';
            carousel.style.transform = `translateX(${offset}px)`;

            cards.forEach((card, index) => {
                if (index === currentStep) {
                    card.classList.add('focused');
                    card.style.opacity = '1';
                    card.style.filter = 'none';
                } else {
                    card.classList.remove('focused');
                    card.style.opacity = '0.3';
                    card.style.filter = 'grayscale(0.5)';
                }
            });
        });
        updateButtonVisibility();
    }

    function getOffset() {
        const containerWidth = carousel.offsetWidth;
        const initialOffset = (containerWidth - cardWidth) / 2;
        return initialOffset - (currentStep * totalCardWidth);
    }

    function nextStep() {
        currentStep = Math.min(currentStep + 1, cards.length - 1);
        updateDesktop(currentStep);
        updateMobile();
    }

    function prevStep() {
        currentStep = Math.max(currentStep - 1, 0);
        updateDesktop(currentStep);
        updateMobile();
    }

    function startAutoAdvance() {
        stopAutoAdvance();
        if (!isInteracting && !isHovering && window.innerWidth >= 992) {
            intervalId = setInterval(nextStep, 5000);
        }
    }

    function stopAutoAdvance() {
        clearInterval(intervalId);
    }

    stepLabels.forEach((label, index) => {
        label.addEventListener('mouseenter', () => {
            isInteracting = true;
            isHovering = true;
            currentStep = index;
            updateDesktop(currentStep);
            updateMobile();
            stopAutoAdvance();
        });

        label.addEventListener('mouseleave', () => {
            isInteracting = false;
            isHovering = false;
            startAutoAdvance();
        });

        label.addEventListener('click', () => {
            isInteracting = true;
            currentStep = index;
            updateDesktop(currentStep);
            updateMobile();
            setTimeout(() => {
                isInteracting = false;
                startAutoAdvance();
            }, 300);
        });
    });

    prevBtn.addEventListener('click', prevStep);
    nextBtn.addEventListener('click', nextStep);

    let startX; let startY; let distX; let distY; let startTime; let
        elapsedTime;
    let isSwiping = false;

    function handleSwipeStart(e) {
        const touch = e.type === 'touchstart' ? e.touches[0] : e;
        startX = touch.pageX;
        startY = touch.pageY;
        distX = 0;
        distY = 0;
        startTime = new Date().getTime();
        isSwiping = true;
        isInteracting = true;
        stopAutoAdvance();

        carousel.style.transition = 'none';

        document.addEventListener('mousemove', handleSwipeMove, { passive: false });
        document.addEventListener('touchmove', handleSwipeMove, { passive: false });
        document.addEventListener('mouseup', handleSwipeEnd);
        document.addEventListener('touchend', handleSwipeEnd);
    }

    function handleSwipeMove(e) {
        if (!isSwiping) return;

        const touch = e.type === 'touchmove' ? e.touches[0] : e;
        distX = touch.pageX - startX;
        distY = touch.pageY - startY;

        if (Math.abs(distY) > Math.abs(distX)) {
            return;
        }

        e.preventDefault();
        const currentOffset = getOffset();
        requestAnimationFrame(() => {
            carousel.style.transform = `translateX(${currentOffset + distX}px)`;
        });
    }

    function handleSwipeEnd() {
        if (!isSwiping) return;

        isSwiping = false;
        elapsedTime = new Date().getTime() - startTime;

        const speed = Math.abs(distX) / elapsedTime;
        const isLeftSwipe = distX < 0;

        if (speed > 0.5 || Math.abs(distX) > totalCardWidth / 2) {
            currentStep += isLeftSwipe ? 1 : -1;
        }

        currentStep = Math.max(0, Math.min(currentStep, cards.length - 1));
        updateMobile(true);
        updateDesktop(currentStep);

        document.removeEventListener('mousemove', handleSwipeMove);
        document.removeEventListener('touchmove', handleSwipeMove);
        document.removeEventListener('mouseup', handleSwipeEnd);
        document.removeEventListener('touchend', handleSwipeEnd);

        setTimeout(() => {
            isInteracting = false;
            startAutoAdvance();
        }, 300);
    }

    mobileCarousel.addEventListener('mousedown', handleSwipeStart);
    mobileCarousel.addEventListener('touchstart', handleSwipeStart, { passive: true });

    updateDimensions();
    updateDesktop(currentStep);
    updateMobile(false);
    startAutoAdvance();

    window.addEventListener('resize', () => {
        if (window.innerWidth >= 992) {
            updateDesktop(currentStep);
        } else {
            updateMobile(false);
        }
    });
}

docReady(() => {
    initHowWeHelp();
});
